var render = function () {
  var _vm$$v, _vm$$v$investmentData, _vm$$v3, _vm$$v3$investmentDat, _vm$$v5, _vm$$v5$investmentDat, _vm$$v5$investmentDat2, _vm$$v6, _vm$$v6$investmentDat, _vm$$v6$investmentDat2, _vm$$v7, _vm$$v7$investmentDat, _vm$$v7$investmentDat2, _vm$$v8, _vm$$v8$investmentDat, _vm$$v8$investmentDat2, _vm$$v9, _vm$$v9$investmentDat, _vm$$v9$investmentDat2, _vm$$v10, _vm$$v10$investmentDa, _vm$$v10$investmentDa2, _vm$$v11, _vm$$v11$investmentDa, _vm$$v11$investmentDa2, _vm$$v12, _vm$$v12$investmentDa, _vm$$v12$investmentDa2, _vm$$v13, _vm$$v13$investmentDa, _vm$$v13$investmentDa2, _vm$$v14, _vm$$v14$investmentDa, _vm$$v14$investmentDa2, _vm$$v15, _vm$$v15$investmentDa, _vm$$v15$investmentDa2, _vm$$v15$investmentDa3, _vm$$v16, _vm$$v16$investmentDa, _vm$$v16$investmentDa2, _vm$$v17, _vm$$v17$investmentDa, _vm$$v17$investmentDa2, _vm$$v18, _vm$$v18$investmentDa, _vm$$v18$investmentDa2, _vm$$v19, _vm$$v19$investmentDa, _vm$$v19$investmentDa2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_vm.skip2ndStep ? _c('div', {
    staticClass: "content"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": true
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('portal', {
    attrs: {
      "to": "actions"
    }
  }, [_vm.viewType === 'previewInvestor' ? _c('GenproxButton', {
    staticClass: "outline gn-secondary",
    attrs: {
      "icon": _vm.PencilSquareIcon,
      "outline": true
    },
    on: {
      "click": function click($event) {
        _vm.$router.push("/".concat(_vm.$route.path.includes('company') ? 'company' : 'fund', "/investor/").concat(_vm.investorId, "/edit/form/step-one"));
      }
    }
  }, [_vm._v("Edit")]) : _vm._e()], 1), _c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm.viewType === 'newInvestor' ? _c('span', [_vm._v("Add new investor")]) : _vm._e(), _vm.viewType === 'editInvestor' ? _c('span', [_vm._v("Edit investor")]) : _vm._e(), _vm.viewType === 'verifyInvestor' ? _c('span', [_vm._v("Authorize your data")]) : _vm._e(), _vm.viewType === 'previewInvestor' ? _c('span', [_vm._v("Investor details")]) : _vm._e()]), _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Detail data ")]), _vm.viewType === 'verifyInvestor' ? [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FIRST NAME',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$investmentData = _vm$$v.investmentData) === null || _vm$$v$investmentData === void 0 ? void 0 : _vm$$v$investmentData.firstName
    },
    on: {
      "blur": function blur($event) {
        var _vm$$v2, _vm$$v2$investmentDat, _vm$$v2$investmentDat2;

        (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$investmentDat = _vm$$v2.investmentData) === null || _vm$$v2$investmentDat === void 0 ? void 0 : (_vm$$v2$investmentDat2 = _vm$$v2$investmentDat.firstName) === null || _vm$$v2$investmentDat2 === void 0 ? void 0 : _vm$$v2$investmentDat2.$touch();
      }
    },
    model: {
      value: _vm.investmentData.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "firstName", $$v);
      },
      expression: "investmentData.firstName"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'SURNAME',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$investmentDat = _vm$$v3.investmentData) === null || _vm$$v3$investmentDat === void 0 ? void 0 : _vm$$v3$investmentDat.surname
    },
    on: {
      "blur": function blur($event) {
        var _vm$$v4, _vm$$v4$investmentDat, _vm$$v4$investmentDat2;

        (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$investmentDat = _vm$$v4.investmentData) === null || _vm$$v4$investmentDat === void 0 ? void 0 : (_vm$$v4$investmentDat2 = _vm$$v4$investmentDat.surname) === null || _vm$$v4$investmentDat2 === void 0 ? void 0 : _vm$$v4$investmentDat2.$touch();
      }
    },
    model: {
      value: _vm.investmentData.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "surname", $$v);
      },
      expression: "investmentData.surname"
    }
  })] : _vm._e(), _vm.displayField('title') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.investorsSelects.title,
      "label": 'TITLE',
      "placeholder": 'Mr.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v5 = _vm.$v) === null || _vm$$v5 === void 0 ? void 0 : (_vm$$v5$investmentDat = _vm$$v5.investmentData) === null || _vm$$v5$investmentDat === void 0 ? void 0 : (_vm$$v5$investmentDat2 = _vm$$v5$investmentDat.details) === null || _vm$$v5$investmentDat2 === void 0 ? void 0 : _vm$$v5$investmentDat2.title
    },
    model: {
      value: _vm.investmentData.details.title,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "title", $$v);
      },
      expression: "investmentData.details.title"
    }
  }) : _vm._e(), _vm.displayField('middle_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'MIDDLE NAME',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v6 = _vm.$v) === null || _vm$$v6 === void 0 ? void 0 : (_vm$$v6$investmentDat = _vm$$v6.investmentData) === null || _vm$$v6$investmentDat === void 0 ? void 0 : (_vm$$v6$investmentDat2 = _vm$$v6$investmentDat.details) === null || _vm$$v6$investmentDat2 === void 0 ? void 0 : _vm$$v6$investmentDat2.middleName
    },
    model: {
      value: _vm.investmentData.details.middleName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "middleName", $$v);
      },
      expression: "investmentData.details.middleName"
    }
  }) : _vm._e(), _c('div', [_c('div', {
    staticClass: "d-flex flex-row align-items-end"
  }, [_vm.displayField('birth_date') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'BIRTH DAY',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v7 = _vm.$v) === null || _vm$$v7 === void 0 ? void 0 : (_vm$$v7$investmentDat = _vm$$v7.investmentData) === null || _vm$$v7$investmentDat === void 0 ? void 0 : (_vm$$v7$investmentDat2 = _vm$$v7$investmentDat.details) === null || _vm$$v7$investmentDat2 === void 0 ? void 0 : _vm$$v7$investmentDat2.birthDate
    },
    model: {
      value: _vm.investmentData.details.birthDate,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "birthDate", $$v);
      },
      expression: "investmentData.details.birthDate"
    }
  }) : _vm._e()], 1)]), _vm.displayField('birth_place') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'BIRTH PLACE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v8 = _vm.$v) === null || _vm$$v8 === void 0 ? void 0 : (_vm$$v8$investmentDat = _vm$$v8.investmentData) === null || _vm$$v8$investmentDat === void 0 ? void 0 : (_vm$$v8$investmentDat2 = _vm$$v8$investmentDat.details) === null || _vm$$v8$investmentDat2 === void 0 ? void 0 : _vm$$v8$investmentDat2.birthPlace
    },
    model: {
      value: _vm.investmentData.details.birthPlace,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "birthPlace", $$v);
      },
      expression: "investmentData.details.birthPlace"
    }
  }) : _vm._e(), _vm.displayField('fathers_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FATHERS NAME',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v9 = _vm.$v) === null || _vm$$v9 === void 0 ? void 0 : (_vm$$v9$investmentDat = _vm$$v9.investmentData) === null || _vm$$v9$investmentDat === void 0 ? void 0 : (_vm$$v9$investmentDat2 = _vm$$v9$investmentDat.details) === null || _vm$$v9$investmentDat2 === void 0 ? void 0 : _vm$$v9$investmentDat2.fathersName
    },
    model: {
      value: _vm.investmentData.details.fathersName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "fathersName", $$v);
      },
      expression: "investmentData.details.fathersName"
    }
  }) : _vm._e(), _vm.displayField('mothers_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'MOTHERS NAME',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v10 = _vm.$v) === null || _vm$$v10 === void 0 ? void 0 : (_vm$$v10$investmentDa = _vm$$v10.investmentData) === null || _vm$$v10$investmentDa === void 0 ? void 0 : (_vm$$v10$investmentDa2 = _vm$$v10$investmentDa.details) === null || _vm$$v10$investmentDa2 === void 0 ? void 0 : _vm$$v10$investmentDa2.mothersName
    },
    model: {
      value: _vm.investmentData.details.mothersName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "mothersName", $$v);
      },
      expression: "investmentData.details.mothersName"
    }
  }) : _vm._e(), _vm.displayField('phone_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'PHONE NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v11 = _vm.$v) === null || _vm$$v11 === void 0 ? void 0 : (_vm$$v11$investmentDa = _vm$$v11.investmentData) === null || _vm$$v11$investmentDa === void 0 ? void 0 : (_vm$$v11$investmentDa2 = _vm$$v11$investmentDa.details) === null || _vm$$v11$investmentDa2 === void 0 ? void 0 : _vm$$v11$investmentDa2.phone
    },
    model: {
      value: _vm.investmentData.details.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "phone", $$v);
      },
      expression: "investmentData.details.phone"
    }
  }) : _vm._e(), _vm.displayField('identification_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'IDENTIFICATION NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v12 = _vm.$v) === null || _vm$$v12 === void 0 ? void 0 : (_vm$$v12$investmentDa = _vm$$v12.investmentData) === null || _vm$$v12$investmentDa === void 0 ? void 0 : (_vm$$v12$investmentDa2 = _vm$$v12$investmentDa.details) === null || _vm$$v12$investmentDa2 === void 0 ? void 0 : _vm$$v12$investmentDa2.identificationNumber
    },
    model: {
      value: _vm.investmentData.details.identificationNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "identificationNumber", $$v);
      },
      expression: "investmentData.details.identificationNumber"
    }
  }) : _vm._e(), _vm.displayField('document_type') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.investorsSelects.documentType,
      "label": 'DOCUMENT TYPE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v13 = _vm.$v) === null || _vm$$v13 === void 0 ? void 0 : (_vm$$v13$investmentDa = _vm$$v13.investmentData) === null || _vm$$v13$investmentDa === void 0 ? void 0 : (_vm$$v13$investmentDa2 = _vm$$v13$investmentDa.details) === null || _vm$$v13$investmentDa2 === void 0 ? void 0 : _vm$$v13$investmentDa2.documentType
    },
    model: {
      value: _vm.investmentData.details.documentType,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "documentType", $$v);
      },
      expression: "investmentData.details.documentType"
    }
  }) : _vm._e(), _vm.displayField('document_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'DOCUMENT NO.',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v14 = _vm.$v) === null || _vm$$v14 === void 0 ? void 0 : (_vm$$v14$investmentDa = _vm$$v14.investmentData) === null || _vm$$v14$investmentDa === void 0 ? void 0 : (_vm$$v14$investmentDa2 = _vm$$v14$investmentDa.details) === null || _vm$$v14$investmentDa2 === void 0 ? void 0 : _vm$$v14$investmentDa2.documentNumber
    },
    model: {
      value: _vm.investmentData.details.documentNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "documentNumber", $$v);
      },
      expression: "investmentData.details.documentNumber"
    }
  }) : _vm._e(), _c('div', [_c('div', {
    staticClass: "expiry-date"
  }, [_c('div', {
    staticClass: "expiry-date__checkbox"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "label": "INDEFINITELY",
      "disabled": _vm.isReadOnly
    },
    model: {
      value: _vm.investmentData.details.documentExpiryDate.indefinitely,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details.documentExpiryDate, "indefinitely", $$v);
      },
      expression: "investmentData.details.documentExpiryDate.indefinitely"
    }
  })], 1)]), _vm.displayField('document_expiry_date') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'DOCUMENT EXPIRY DATE',
      "disabledDate": _vm.disabledDate,
      "disabled": _vm.isDocumentExpiryFieldDisabled || _vm.isReadOnly,
      "validation": (_vm$$v15 = _vm.$v) === null || _vm$$v15 === void 0 ? void 0 : (_vm$$v15$investmentDa = _vm$$v15.investmentData) === null || _vm$$v15$investmentDa === void 0 ? void 0 : (_vm$$v15$investmentDa2 = _vm$$v15$investmentDa.details) === null || _vm$$v15$investmentDa2 === void 0 ? void 0 : (_vm$$v15$investmentDa3 = _vm$$v15$investmentDa2.documentExpiryDate) === null || _vm$$v15$investmentDa3 === void 0 ? void 0 : _vm$$v15$investmentDa3.expiryDate
    },
    model: {
      value: _vm.investmentData.details.documentExpiryDate.expiryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details.documentExpiryDate, "expiryDate", $$v);
      },
      expression: "investmentData.details.documentExpiryDate.expiryDate"
    }
  }) : _vm._e()], 1), _vm.displayField('document_country') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": "DOCUMENT COUNTRY",
      "menu-props": {
        top: true,
        offsetY: true
      },
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v16 = _vm.$v) === null || _vm$$v16 === void 0 ? void 0 : (_vm$$v16$investmentDa = _vm$$v16.investmentData) === null || _vm$$v16$investmentDa === void 0 ? void 0 : (_vm$$v16$investmentDa2 = _vm$$v16$investmentDa.details) === null || _vm$$v16$investmentDa2 === void 0 ? void 0 : _vm$$v16$investmentDa2.documentCountry,
      "searchable": true
    },
    model: {
      value: _vm.investmentData.details.documentCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "documentCountry", $$v);
      },
      expression: "investmentData.details.documentCountry"
    }
  }) : _vm._e(), _vm.displayField('tax_office') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'TAX OFFICE',
      "disabled": _vm.isReadOnly,
      "validation": (_vm$$v17 = _vm.$v) === null || _vm$$v17 === void 0 ? void 0 : (_vm$$v17$investmentDa = _vm$$v17.investmentData) === null || _vm$$v17$investmentDa === void 0 ? void 0 : (_vm$$v17$investmentDa2 = _vm$$v17$investmentDa.details) === null || _vm$$v17$investmentDa2 === void 0 ? void 0 : _vm$$v17$investmentDa2.taxOffice
    },
    model: {
      value: _vm.investmentData.details.taxOffice,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "taxOffice", $$v);
      },
      expression: "investmentData.details.taxOffice"
    }
  }) : _vm._e(), _vm.displayField('country') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'COUNTRY OF TAX RESIDENCE',
      "menu-props": {
        top: true,
        offsetY: true
      },
      "validation": (_vm$$v18 = _vm.$v) === null || _vm$$v18 === void 0 ? void 0 : (_vm$$v18$investmentDa = _vm$$v18.investmentData) === null || _vm$$v18$investmentDa === void 0 ? void 0 : (_vm$$v18$investmentDa2 = _vm$$v18$investmentDa.details) === null || _vm$$v18$investmentDa2 === void 0 ? void 0 : _vm$$v18$investmentDa2.country,
      "disabled": _vm.isReadOnly,
      "searchable": true
    },
    model: {
      value: _vm.investmentData.details.country,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "country", $$v);
      },
      expression: "investmentData.details.country"
    }
  }) : _vm._e(), _vm.displayField('nationality') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'NATIONALITY',
      "menu-props": {
        top: true,
        offsetY: true
      },
      "validation": (_vm$$v19 = _vm.$v) === null || _vm$$v19 === void 0 ? void 0 : (_vm$$v19$investmentDa = _vm$$v19.investmentData) === null || _vm$$v19$investmentDa === void 0 ? void 0 : (_vm$$v19$investmentDa2 = _vm$$v19$investmentDa.details) === null || _vm$$v19$investmentDa2 === void 0 ? void 0 : _vm$$v19$investmentDa2.nationality,
      "disabled": _vm.isReadOnly,
      "searchable": true
    },
    model: {
      value: _vm.investmentData.details.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "nationality", $$v);
      },
      expression: "investmentData.details.nationality"
    }
  }) : _vm._e(), _vm.viewType === 'editInvestor' ? _c('div', {
    staticClass: "add-new-investor-form__propagate"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary propagate",
    attrs: {
      "value": _vm.propagate,
      "removeWatcher": true,
      "label": "PROPAGATE THE DATA"
    },
    on: {
      "changedValue": _vm.setPropagate
    }
  }), _c('p', {
    class: ['add-new-investor-form__note', !_vm.propagate ? 'hidden' : '']
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Important!")]), _vm._v(" Remember that this action will result in changes across all Legal Entities")])], 1) : _vm._e(), !(_vm.viewType === 'previewInvestor' && _vm.skip3rdStep) ? _c('GenproxButton', {
    staticClass: "filled gn-primary button secondary",
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(_vm._s(_vm.skip3rdStep ? 'Save' : 'Next'))]) : _vm._e(), _c('div', {
    staticClass: "cancel-button",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }